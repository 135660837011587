module.exports = {
  development: {
    baseURL: '/dev-api',
    mockURL: 'http://www.meet.org'
  },
  production: {
    baseURL: '/prod-api',
    mockURL: 'http://meet-admin.91tests.net/'
  }
}
