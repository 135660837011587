//微卡网页授权跳转
export function getUserCode() {
  //跳转路径

  const redirectUrl = encodeURIComponent(
    "https://wkmeet.91tests.net" 
  );
  window.location.href =
    "https://open.wecard.qq.com/connect/oauth/authorize?app_key=4CC264C78D7404AB&response_type=code&scope=snsapi_base&ocode=1681190836"+
    "&redirect_uri=" +
    redirectUrl 
  
}